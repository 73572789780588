import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  startSpinner,
  stopSpinner,
  showPopUp,
} from "../../../redux/authActions";

import {
  fetchAssignmentDataSuccess,
  getAssignedGroupsSuccess,
  getAssignmentResultSuccess,
  getCurrentActivitySuccess,
  getStudentListSuccess,
  selectedLessonDataSuccess,
  getAssignmentResponseDataSuccess,
} from "../actions/assignmentAction";
import * as actions from "../actions/actionType";
import { doGet, doPost } from "../../../utils/ajaxHelper";
import { url } from "../../../utils/apiEndpoints";

export function* fetchAssignmentData(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.assignment.getAssignmentData}?to_date=${
      action.payload.to_date ?? ""
    }&from_date=${action.payload.from_date ?? ""}&user_puid=${
      action.payload.user_puid
    }`;
    const assignmentData = yield call(doGet, fetchURL);
    if (assignmentData?.data?.status === "success") {
      yield put(fetchAssignmentDataSuccess(assignmentData?.data));
    } else {
      yield put(fetchAssignmentDataSuccess(null));
    }
  } catch (error) {
    yield put(showPopUp(501));
    yield put(fetchAssignmentDataSuccess(null));
  } finally {
    yield put(stopSpinner());
  }
}
export function* getAssignedGroups(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.assignment.getAssignedGroups}?user_puid=${action.payload.user_puid}&lesson_id=${action.payload.lesson_id}`;
    const assignedGroupsData = yield call(doGet, fetchURL);
    if (assignedGroupsData?.data?.status === "success") {
      yield put(getAssignedGroupsSuccess(assignedGroupsData?.data));
    } else {
      yield put(getAssignedGroupsSuccess(null));
    }
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getAssignedGroupsSuccess(null));
  } finally {
    yield put(stopSpinner());
  }
}
export function* getAssignmentResult(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.assignment.getAssignmentResult}?group_puid=${action.payload.group_puid}&lesson_id=${action.payload.lesson_id}&group_assignment_id=${action.payload.group_assignment_id}`;
    const assignmentResultData = yield call(doGet, fetchURL);
    if (assignmentResultData?.data?.status === "success") {
      yield put(getAssignmentResultSuccess(assignmentResultData?.data));
    } else {
      yield put(getAssignmentResultSuccess(null));
    }
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getAssignmentResultSuccess(null));
  } finally {
    yield put(stopSpinner());
  }
}
export const getProject = (state) => state.assignment?.assignedGroups;
export function* invokeAssignmentReportSagas(action) {
  yield* getAssignedGroups({ payload: action.payload[0] });
  let project = yield select(getProject);
  yield* getAssignmentResult({
    payload: { ...action.payload[1], group_puid: project?.data[0]?.group_puid },
  });
}

export function* getCurrentActivity(action) {
  try {
    yield put(startSpinner());
    yield put(getCurrentActivitySuccess(action.payload));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getCurrentActivitySuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* getStudentList(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.assignment.getStudentList}?group_assignment_id=${action.payload.group_assignment_id}`;
    const studentListData = yield call(doGet, fetchURL);
    if (studentListData?.data?.status === "success") {
      yield put(getStudentListSuccess(studentListData?.data?.data));
    } else {
      yield put(getStudentListSuccess(null));
    }
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getStudentListSuccess(null));
  } finally {
    yield put(stopSpinner());
  }
}

export function* getSelectedLessonData(action) {
  try {
    yield put(startSpinner());
    yield put(selectedLessonDataSuccess(action.payload));
  } catch (error) {
    yield put(showPopUp(501));
    yield put(selectedLessonDataSuccess(null));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

export function* getAssignmentResponseData(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.assignment.getAssignmentResponse}?user_Puid=${action.payload.user_Puid}&lesson_id=${action.payload.lesson_id}`;
    const assignmentResponseData = yield call(doGet, fetchURL);
    if (assignmentResponseData?.data?.status === "success") {
      yield put(getAssignmentResponseDataSuccess(assignmentResponseData?.data));
    } else {
      yield put(getAssignmentResponseDataSuccess(null));
    }
  } catch (error) {
    yield put(showPopUp(501));
    yield put(getAssignmentResponseDataSuccess(null));
  } finally {
    yield put(stopSpinner());
  }
}

export function* callFetchAssignmentData() {
  yield takeLatest(actions.FETCH_ASSIGNMENT_DATA, fetchAssignmentData);
}

export function* callGetAssignedGroups() {
  yield takeLatest(actions.GET_ASSIGNED_GROUPS, getAssignedGroups);
}

export function* callGetAssignmentResult() {
  yield takeLatest(actions.GET_ASSIGNMENT_RESULT, getAssignmentResult);
}
export function* callinvokeAssignmentReportSagas() {
  yield takeLatest(
    actions.GET_ASSIGNMENT_RESULT_AND_GROUPS,
    invokeAssignmentReportSagas
  );
}

export function* callgetCurrentActivity() {
  yield takeLatest(actions.GET_CURRENT_ACTIVITY, getCurrentActivity);
}

export function* callGetstudentList() {
  yield takeLatest(actions.GET_STUDENT_LIST, getStudentList);
}

export function* callSelectedLessonData() {
  yield takeLatest(actions.SELECTED_lESSON_DATA, getSelectedLessonData);
}

export function* callGetAssignmentResponseData() {
  yield takeLatest(actions.GET_ASSIGNMENT_RESPONSE, getAssignmentResponseData);
}

export function* assignmentSaga() {
  yield all([
    callFetchAssignmentData(),
    callGetAssignedGroups(),
    callGetAssignmentResult(),
    callinvokeAssignmentReportSagas(),
    callgetCurrentActivity(),
    callGetstudentList(),
    callSelectedLessonData(),
    callGetAssignmentResponseData(),
  ]);
}
