export const FETCH_ASSIGNMENT_DATA = "FETCH_ASSIGNMENT_DATA";
export const FETCH_ASSIGNMENT_DATA_SUCCESS = "FETCH_ASSIGNMENT_DATA_SUCCESS";
export const GET_ASSIGNED_GROUPS = "GET_ASSIGNED_GROUPS";
export const GET_ASSIGNED_GROUPS_SUCCESS = "GET_ASSIGNED_GROUPS_SUCCESS";
export const GET_ASSIGNMENT_RESULT = "GET_ASSIGNMENT_RESULT";
export const GET_ASSIGNMENT_RESULT_SUCCESS = "GET_ASSIGNMENT_RESULT_SUCCESS";
export const GET_ASSIGNMENT_RESULT_AND_GROUPS = "GET_ASSIGNMENT_REPORT";
export const GET_CURRENT_ACTIVITY = "GET_CURRENT_ACTIVITY"
export const GET_CURRENT_ACTIVITY_SUCCESS ="GET_CURRENT_ACTIVITY_SUCCESS"
export const GET_STUDENT_LIST = "GET_STUDENT_LIST";
export const GET_STUDENT_LIST_SUCCESS = "GET_STUDENT_LIST_SUCCESS";
export const SELECTED_lESSON_DATA = "SELECTED_lESSON_DATA";
export const SELECTED_lESSON_DATA_SUCCESS = "SELECTED_lESSON_DATA_SUCCESS";
export const GET_ASSIGNMENT_RESPONSE = "GET_ASSIGNMENT_RESPONSE";
export const GET_ASSIGNMENT_RESPONSE_SUCCESS = "GET_ASSIGNMENT_RESPONSE_SUCCESS";