import * as actions from "../actions/actionType";
import { selectedLessonData } from "../actions/assignmentAction";

const initialState = {
  assignmentListData: null,
  dateRange: {
    fromDate: null,
    toDate: null,
  },
  assignedGroups: null,
  assignmentResult: null,
  getNextActivityData: null,
  currentActivityData: null,
  studentList: null,
  selectedLesson: null,
};

export const assignmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_ASSIGNMENT_DATA_SUCCESS:
      return {
        ...state,
        assignmentListData: action.payload,
      };
    case actions.GET_ASSIGNED_GROUPS_SUCCESS:
      return {
        ...state,
        assignedGroups: action.payload,
      };
    case actions.GET_ASSIGNMENT_RESULT_SUCCESS:
      return {
        ...state,
        assignmentResult: action.payload,
      };
    case actions.GET_CURRENT_ACTIVITY_SUCCESS:
      return { ...state, currentActivityData: action.payload };
    case actions.GET_STUDENT_LIST_SUCCESS:
      return { ...state, studentList: action.payload };
    case actions.SELECTED_lESSON_DATA_SUCCESS:
      return { ...state, selectedLesson: action.payload };
    case actions.GET_ASSIGNMENT_RESPONSE_SUCCESS:
      return { ...state, assignmentResponseData: action.payload };
      
    default:
      return state;
  }
};
