import * as actions from "./actionType";

export const fetchAssignmentData = (payload) => ({
  type: actions.FETCH_ASSIGNMENT_DATA,
  payload,
});

export const fetchAssignmentDataSuccess = (payload) => ({
  type: actions.FETCH_ASSIGNMENT_DATA_SUCCESS,
  payload,
});
export const getAssignedGroups = (payload) => ({
  type: actions.GET_ASSIGNED_GROUPS,
  payload,
});
export const getAssignedGroupsSuccess = (payload) => ({
  type: actions.GET_ASSIGNED_GROUPS_SUCCESS,
  payload,
});
export const getAssignmentResultData = (payload) => ({
  type: actions.GET_ASSIGNMENT_RESULT,
  payload,
});
export const getAssignmentResultSuccess = (payload) => ({
  type: actions.GET_ASSIGNMENT_RESULT_SUCCESS,
  payload,
});
export const getAssignmentResultAndGroups = (payload) => ({
  type: actions.GET_ASSIGNMENT_RESULT_AND_GROUPS,
  payload,
});

export const getCurrentActivity = (payload) => ({
  type: actions.GET_CURRENT_ACTIVITY,
  payload: payload,
});
export const getCurrentActivitySuccess = (payload) => ({
  type: actions.GET_CURRENT_ACTIVITY_SUCCESS,
  payload: payload,
});
export const getStudentList = (payload) => ({
  type: actions.GET_STUDENT_LIST,
  payload,
});
export const getStudentListSuccess = (payload) => ({
  type: actions.GET_STUDENT_LIST_SUCCESS,
  payload,
});

export const selectedLessonData = (payload) => ({
  type: actions.SELECTED_lESSON_DATA,
  payload,
});
export const selectedLessonDataSuccess = (payload) => ({
  type: actions.SELECTED_lESSON_DATA_SUCCESS,
  payload,
});

export const getAssignmentResponseData = (payload) => ({
  type: actions.GET_ASSIGNMENT_RESPONSE,
  payload,
});

export const getAssignmentResponseDataSuccess = (payload) => ({
  type: actions.GET_ASSIGNMENT_RESPONSE_SUCCESS,
  payload,
});
